import {
  AUTHORIZE,
  NO_LOGIN_OR_PASSWORD_AUTH,
  BEGIN_LOGIN,
  SUCCESS_LOGIN,
  UNSUCCESS_LOGIN,
  LOGOUT,
  SELECT_LANGUAGE,
  CLEAN_SELECTED_TENANTMENU,
  SELECTED_TENANTMENU
} from "../constants/LoginConstants";
import { Config } from "../configs";
import { createHashHistory } from "history";
import { showAlertAction } from "./AlertsActions";
import i18next from "i18next";
import axios from "axios";

export const getAuthorized = (login, password) => ({
  type: AUTHORIZE,
  payload: { login: login, password: password },
});

export const noLoginOrPasswordAuth = (noLogin, noPassword) => ({
  type: NO_LOGIN_OR_PASSWORD_AUTH,
  payload: { noLogin, noPassword },
});

export const beginLogin = (login, password) => {
  return function (dispatch) {
    let history = createHashHistory();
    dispatch({ type: BEGIN_LOGIN });
    const formData = new FormData();
    formData.append("login", login);
    formData.append("password", password);

    return axios.post(Config.loginUrl(), formData,{  withCredentials: true,
      nctPublic: true,})
      .then((response) => {    
        if ((response.data.result.role !== (1) && response.data.result.role !== (3))) {          
          dispatch(unsuccessLogin());
          dispatch(showAlertAction(i18next.t("fail_auth"), "warning")); 
          return
        }
        if (parseInt(response.status / 200) === 1) {          
          localStorage.setItem("refresh_token", response.data.result["refresh_token"]);
          localStorage.setItem("access_token", response.data.result["access_token"]);
          localStorage.setItem("expire", response.data.result["expire"]);       
          localStorage.setItem("tenant", response.data.result["tenant-id"]);
          localStorage.setItem("role", response.data.result["role"]);
          dispatch(successLogin(response.data.result.token));
          history.push("/users");          
        }        
      })
      .catch((response) => {        
        if (parseInt(response.status / 400) === 1) {
          dispatch(unsuccessLogin());
          dispatch(showAlertAction(i18next.t("fail_auth"), "warning"));          
        } else if (parseInt(response.status / 500) === 1) {
          dispatch(unsuccessLogin());
          dispatch(showAlertAction("Error", "error"));  
        }
      });
  };
};

export const successLogin = (token) => ({
  type: SUCCESS_LOGIN,
  payload: { token },
});

export const unsuccessLogin = () => ({
  type: UNSUCCESS_LOGIN,
});

export const logout = () => {
  const history = createHashHistory();
  localStorage.removeItem("tenant");
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("access_token");
  localStorage.removeItem("expire");
  //add tokens
  history.push("/login");
  return {
    type: LOGOUT,
  };
};
export const getLanguage = (data) => ({
  type: SELECT_LANGUAGE,
  payload: data,
});

export const getSelectedTenantMenu = (tenant, menuItem) => {
  return ({
    type: SELECTED_TENANTMENU,
    payload: { tenant: tenant, menuItem: menuItem }
  })
};

export const cleanSelectedTenantMenu = () => ({
  type: SELECTED_TENANTMENU,
  payload: "",
});
