export const BEGIN_GROUPS_FETCH = "BEGIN_GROUPS_FETCH";
export const SUCCESS_GROUPS_FETCH = "SUCCESS_GROUPS_FETCH";
export const ADDING_NEW_GROUP = "ADDING_NEW_GROUP";
export const ADDED_NEW_GROUP = "ADDED_NEW_GROUP";
export const BEGIN_RESOURCES_FETCH = "BEGIN_RESOURES_FETCH";
export const SUCCESS_RESOURCES_FETCH = "SUCCESS_RESOURCES_FETCH";
export const ADDING_RESOURCE_TO_GROUP = "ADDING_RESOURCE_TO_GROUP";
export const ADDED_RESOURCE_TO_GROUP = "ADDED_RESOURCE_TO_GROUP";
export const CLEAN_SELECTED_GROUP = "CLEAN_SELECTED_GROUP";
export const DELETING_RESOURCE = "DELETING_RESOURCE";
export const DELETED_RESOURCE = "DELETED_RESOURCE";
export const DELETING_GROUP = "DELETING_GROUP";
export const DELETED_GROUP = "DELETED_GROUP";
export const BEGIN_RESOURCE_FETCH = "BEGIN_RESOURCE_FETCH";
export const SUCCESS_RESOURCE_FETCH = "SUCCESS_RESOURCE_FETCH";
export const UPDATING_RESOURCE = "UPDATING_RESOURCE";
export const UPDATED_RESOURCE = "UPDATED_RESOURCE";
export const STOP_LOADING = "STOP_LOADING";

