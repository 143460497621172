import React from "react";
import { connect } from "react-redux";
import { hideAlertAction } from "../../actions/AlertsActions.js";
import BasicAlert from "@material-ui/lab/Alert";
import Grow from '@material-ui/core/Grow';
import '../../styles/Alert.css';


const mapStateToProps = (state) => ({
  showAlert: state.alert.showAlert,
  message: state.alert.message,
  alertType: state.alert.alertType,
});

const mapDispatchToProps = (dispatch) => ({
  hideAlertAction: () => dispatch(hideAlertAction()),
});

class Alert extends React.Component {
  render() {
    let showAlert = this.props.showAlert;
    return (
      <Grow in={showAlert} style={{ transformOrigin: '0 0 0' }} {...(showAlert ? { timeout: 1000 } : {})}>
        <BasicAlert
        className="alert"
          style={{
            alignItems: "center",
            textAlign: "center",
            position: "fixed",
            minWidth: "300px",
            margin: "20px 400px",
            // left: 0,
            display: showAlert ? "flex" : "none",
            // right: 0,
            zIndex: 1000,
          }}
          onClose={this.props.hideAlert}
          variant="filled"
          severity={this.props.alertType}
        >
          <div>{this.props.message}</div>
        </BasicAlert>
      </Grow>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
