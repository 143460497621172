import {
  BEGIN_USERS_FETCH,
  SUCCESS_USERS_FETCH,
  BEGIN_USER_FETCH,
  SUCCESS_USER_FETCH,
  ADDING_NEW_USER,
  ADDED_NEW_USER,
  PREVENT_USER_ADD,
  CLEAN_SELECTED_USER,
  BEGIN_DOMAINS_FETCH,
  SUCCESS_DOMAINS_FETCH,
  UPDATING_USER,
  UPDATED_USER,
  DELETED_USER,
  DELETING_USER,
  MIN_LOGIN_LEN,
  MIN_PASSWORD_LEN, 
  USERS_PER_PAGE,
} from "../constants/UsersConstants";
import { Config } from "../configs";
import { createHashHistory } from "history";
import { showAlertAction } from "./AlertsActions";
import i18next from "i18next";
import { validate } from "../components/Validations/EmailValidator";
import { altValidate } from "../components/Validations/EmailValidator";
import axios from "axios";


export const getDomains = () => {
  return function (dispatch) {
    dispatch(beginDomainsFetch());

    return axios.get(Config.domainsUrl()
    // {
    //   headers: { AUTHORIZATION: localStorage.getItem("token") },
    // }
    )
      .then((response) => {
        if (response.status === 200) {
          // dispatch(getPasswordInfo());
          dispatch(successDomainsFetch(response.data));
        } else {
          return dispatch(showAlertAction("Error", "error"));
        }
      })
  };
};

// export const getPasswordInfo = () => {
//   return function(dispatch){
//       return fetch(Config.passwordSettingsUrl(), {
//           headers: { AUTHORIZATION: localStorage.getItem("token") },
//       })
//       .then((response) => response.json())
//         .then((json) => {
//           return dispatch({type: GET_PASSWORD_INFO, payload: json})
//         })
//      .catch((response) => {
//     localStorage.setItem("token", "");
//     localStorage.setItem("tenant", "");
//     let history = createHashHistory();
//     history.push("/login");
//   });
//   }
// }

export const beginDomainsFetch = () => ({
  type: BEGIN_DOMAINS_FETCH,
  payload: { domainsFetching: true },
});

export const successDomainsFetch = (domains) => ({
  type: SUCCESS_DOMAINS_FETCH,
  payload: { domainsFetching: false, domains },
});

export const getUsers = (page, search) => {
  return function (dispatch) {
    dispatch(beginUsersFetch());

    return axios.get(Config.usersUrl(page, search)
    // , {
    //   headers: { AUTHORIZATION: localStorage.getItem("token") }
    // }
    )
      .then((response) => {
        if (response.status === 200) {         
          let users = response.data.results ? response.data.results.users : [];
          const usersPagesCount = Math.ceil(response.data.results.count / 50);
          dispatch(successUsersFetch(users, usersPagesCount, page));
        }
        // else {
        //   return dispatch(showAlertAction("Error", "error"));
        // }
      })
      .catch((response, users, page ) => {
        if (response.status  === 403 || response.status  === 404 || response.status  === 409) {
          dispatch(successUsersFetch([]));
          dispatch(showAlertAction(i18next.t("fail_auth"), "warning"));
        }
        else {          
          dispatch(successUsersFetch([]));
          // dispatch(showAlertAction(i18next.t("no_user_found"), "warning"));
        }
      }
    );
  };
};

export const beginUsersFetch = () => ({
  type: BEGIN_USERS_FETCH,
  payload: { usersFetching: true },
});

export const successUsersFetch = (users, usersPagesCount, page) => ({
  type: SUCCESS_USERS_FETCH,
  payload: { usersFetching: false, users, usersPagesCount, page },
});

export const getUser = (email) => {
  return function (dispatch) {
    dispatch(beginUserFetch(email));
    return axios.get(Config.userUrl(localStorage.getItem("tenant"), email)
    // , {
    //   headers: { AUTHORIZATION: localStorage.getItem("token") },
    // }
    )
      .then((response) => {
        
        if (response.status === 200) {
          dispatch(successUserFetch(response.data.results));
        } else {
          return dispatch(showAlertAction("Error", "error"));
        }
      })
      .catch(() => {        
        localStorage.setItem("token", "");
        localStorage.setItem("tenant", "");
        let history = createHashHistory();
        history.push("/login");
      });
  };
};

export const beginUserFetch = (email) => ({
  type: BEGIN_USER_FETCH,
  payload: { selectedUser: email, userFetching: true },
});

export const successUserFetch = (user) => ({
  type: SUCCESS_USER_FETCH,
  payload: { userFetching: false, user },
});

export const addNewUser = (userInfo) => {
  return function (dispatch, getState) {
    
   
    let {
      cn,
      sn,
      l,
      ou,
      domain,
      altDomain,
      initials,
      title,
      description,
      telephoneNumber,  
      info,
      homePhone,
      email,
      altEmail,
      employeeNumber,
      password,
      quota,
      labeledURI,
      aliases,
    } = userInfo
    
    if (parseFloat(quota) <= 0 || 
        quota.match(/^\./) ||
        quota.match(/\.$/)    
    )
    {
      dispatch(showAlertAction(i18next.t("invalid_quota_value"), "error"));
      return
    }


     // if (parseFloat(quota) > MAX_QUOTA) {
    //   dispatch(showAlertAction(i18next.t("quota_exceeded"), "error"));
    //   return;
    // }
   
    if (
      !validate(`${email}@${domain}`) ||
      email.length < MIN_LOGIN_LEN ||
      email.match(
        /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g
      )
    ) {
      dispatch(showAlertAction(i18next.t("invalid_login_value"), "error"));
      return;
    }

    if (window.enable_alternative_login) {
      if (
        altEmail.length < MIN_LOGIN_LEN ||
        !altValidate(`${altEmail}@${altDomain}`) ||
        altEmail.match(
          /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g
        )
      ) {
        dispatch(showAlertAction(i18next.t("invalid_login_value"), "error"));
        return;
      }
    }

    if (
      `${email}@${domain}`.length > 255 ||
      `${altEmail}@${altDomain}`.length > 255
    ) {
      dispatch(showAlertAction(i18next.t("invalid_login_limit"), "error"));
      return;
    }
    
    // let passwordInfo = getState().users.passwordInfo
    if (password) {
      // if (password.length < 6 || password.length > 128) {
      //   dispatch(showAlertAction(i18next.t("invalid_password_length"), "error"));
      //   return
      // }
      if (password.match(/[а-яА-Я]/g)) {
        dispatch(showAlertAction(i18next.t("users_page.pass_req") + " " + i18next.t("password_only_latin"), "error"));
        return
      }
      // if (password.replace(/[^0-9]/g, "").length < 1) {
      //   dispatch(showAlertAction(i18next.t("password_must_contain_numbers"), "error"));
      //   return
      // }
      // if (((password.match(/[A-ZА-Я]/g) || []).length === 0 || (password.match(/[a-zа-я]/g) || []).length === 0)
      //   // && passwordInfo.needs_both_upper_and_lower
      // ) {
      //   dispatch(showAlertAction(i18next.t("password_lowercase_uppercase"), "error"));
      //   return
      // }     
      // if (password.match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g)) {
      //   dispatch(showAlertAction(i18next.t("no_emoji"), "error"));
      //   return
      // }
      // if ((/[^a-zA-Z0-9.-_!@#$%^&*()]/g, "") || password.match(/[\[\]/]/g, "")|| password.match(/[^{}]/g,"" )) {
      //   dispatch(showAlertAction(i18next.t("invalid_special_characters_password"), "error"));
      //   return
      // }
    }  

    // if (password.replace(/[\w]+/g,"").length < 1)
    // {
    //   dispatch(showAlertAction(i18next.t("special_characters"), "error"));
    //   return
    // }

    const avatar = userInfo.croppedAvatar
    let avatarForm = null
    if (avatar){
        avatarForm = new FormData()
        avatarForm.append("file", avatar)
    }
    let alias = aliases.map(
      (alias) => `"${alias.email}@${alias.domain}"` 
    );
    alias = alias.length > 0 ? `[${alias.join(",")}]` : "";
    quota = Number(quota) * 1048576
    let body = avatarForm
    let mail = `${email}@${domain}`
    let altMail = `${altEmail}@${altDomain}`
    const formData = new FormData();
    dispatch(addingNewUser());
    if (l !== "") { formData.set("l", l) } 
    if (title !== "") {formData.set("title", title)} 
    if (ou !== "") {formData.set("ou", ou)} 
    if (description !== "") {formData.set("description", description)} 
    if (telephoneNumber !== "") {formData.set("telephoneNumber", telephoneNumber)} 
    if (homePhone !== "") {formData.set("homePhone", homePhone)} 
    if (info !== "") {formData.set("info", info)} 
    formData.set("cn", cn);
    formData.set("sn", sn);
    if (initials !== "") {formData.set("initials", initials)} 
    formData.set("userPassword", password)
    if (password !== "") { formData.set("userPassword", password) } 
    formData.set("employeeNumber", employeeNumber);
    if (alias && alias !=="[]") { formData.set("alias", alias) }     
    formData.set("quota", Math.floor(quota));
    if (window.enable_alternative_login && altEmail && altDomain) { formData.set("mailAlternateAddress", altMail) } 

    axios.put(Config.editDeleteUserUrl(mail), formData)       
      .then((response) => {
        if (parseInt(response.status / 200) === 1) {
          return dispatch(addedNewUser())          
        }
      })
      .catch((response) => {
        if (response.status === 409) {
          if (response.data.extend) {
            if ("min_length" in response.data.extend) {
              dispatch(showAlertAction((i18next.t("users_page.pass_req") + " " + i18next.t("users_page.min_length") + " " + response.data.extend.min_length), "error"))
              return dispatch({ type: PREVENT_USER_ADD })
            }
            if ("max_length" in response.data.extend) {
              dispatch(showAlertAction((i18next.t("users_page.pass_req") + " " + i18next.t("users_page.max_length") + " " + response.data.extend.max_length), "error"))
              return dispatch({ type: PREVENT_USER_ADD })
            }
            if ("wrong_symbol" in response.data.extend) {
              dispatch(showAlertAction((i18next.t("users_page.pass_req") + " " + i18next.t("users_page.wrong_symbol") + " " + response.data.extend.wrong_symbol), "error"))
              return dispatch({ type: PREVENT_USER_ADD })
            }
            if ("min_digits_count" in response.data.extend) {
              dispatch(showAlertAction((i18next.t("users_page.pass_req") + " " + i18next.t("users_page.min_digits_count") + " " + response.data.extend.min_digits_count), "error"))
              return dispatch({ type: PREVENT_USER_ADD })
            }
            if ("min_lowercase_letter_count" in response.data.extend) {
              dispatch(showAlertAction((i18next.t("users_page.pass_req") + " " + i18next.t("users_page.min_lowercase_letter_count") + " " + response.data.extend.min_lowercase_letter_count), "error"))
              return dispatch({ type: PREVENT_USER_ADD })
            } if ("min_uppercase_letter_count" in response.data.extend) {
              dispatch(showAlertAction((i18next.t("users_page.pass_req") + " " + i18next.t("users_page.min_uppercase_letter_count") + " " + response.data.extend.min_uppercase_letter_count), "error"))
              return dispatch({ type: PREVENT_USER_ADD })
            } if ("min_spec_symbols_count" in response.data.extend) {
              dispatch(showAlertAction((i18next.t("users_page.pass_req") + " " + i18next.t("users_page.min_spec_symbols_count") + " " + response.data.extend.min_spec_symbols_count), "error"))
              return dispatch({ type: PREVENT_USER_ADD })
            }
            if (Object.values(response.data.extend).includes("must be greater than or equal to 0")) {
              let paramName = Object.keys(response.data.extend).find(key => response.data.extend[key] === "must be greater than or equal to 0")
              dispatch(showAlertAction(`${i18next.t("users_page.parameter")} ${paramName} : ${i18next.t("users_page.geater_zero")}`, "error"))
              return dispatch({ type: PREVENT_USER_ADD })
            }
            if (Object.values(response.data.extend).includes("must be less than or equal to 65535")) {
              let paramName = Object.keys(response.data.extend).find(key => response.data.extend[key] === "must be less than or equal to 65535")
              dispatch(showAlertAction(`${i18next.t("users_page.parameter")} ${paramName} : ${i18next.t("users_page.less_than")}`, "error"))
              return dispatch({ type: PREVENT_USER_ADD })
            }
            if (response.data.extend.existing_aliases) {
              dispatch(preventUserAdd(response.data.error.code, response.data.extend.existing_aliases))
              return dispatch({ type: PREVENT_USER_ADD })
            };
          }              
          if (response.data.error.code && response.data.error.code === 101) {
            dispatch(showAlertAction(i18next.t("users_page.user_already_exists"), "error"));
            return dispatch({ type: PREVENT_USER_ADD })
          } if (response.data.error.code && response.data.error.code === 102) {
            dispatch(showAlertAction(i18next.t("users_page.email_already_exists"), "error"));
            return dispatch({ type: PREVENT_USER_ADD })
          }
          dispatch(showAlertAction(i18next.t("users_page.error409"), "error"));
          return dispatch({ type: PREVENT_USER_ADD })

        } else if (response.status === 400) {  
          if (response.data?.error?.code === 304) {
            dispatch(showAlertAction(i18next.t("users_page.wrong_mail_format"), "error"))
            return dispatch({type: PREVENT_USER_ADD})
          } else {
            dispatch(showAlertAction(i18next.t("users_page.required_params"), "error"))
            return dispatch({type: PREVENT_USER_ADD})
          }


          dispatch(showAlertAction(i18next.t("users_page.required_params"), "error"))
          return dispatch({type: PREVENT_USER_ADD})   
        }
        else if (response.status === 401 ) {          
          dispatch(showAlertAction(i18next.t("users_page.old_token"), "error"))
          return dispatch({type: PREVENT_USER_ADD})  
        }
        else if (response.status === 403 ) {          
          dispatch(showAlertAction(i18next.t("users_page.no_permission"), "error"))
          return dispatch({type: PREVENT_USER_ADD})          
        
        } else if (response.status === 500 ) {          
          dispatch(showAlertAction(i18next.t("Error"), "error"))
          return dispatch({type: PREVENT_USER_ADD})    
        }

      });
      
  };
};

export const preventUserAdd = (errorType, aliases) => {
  return function (dispatch) {   
    // switch (errorType) {     
    //   case 102:
    //     dispatch(
    //       showAlertAction(i18next.t("users_page.email_already_exists"), "error")
    //     );
    //     break;
    //   default:
    //     break;
    // }   
    localStorage.setItem("aliases", aliases)
    dispatch({ type: PREVENT_USER_ADD });
  };
};

export const addingNewUser = () => ({
  type: ADDING_NEW_USER,
});

export const addedNewUser = () => {
  createHashHistory().push("/users");
  return { type: ADDED_NEW_USER };
};

export const updateUser = (userInfo) => {
  return function (dispatch, getState) {
    
    let {
      cn,
      sn,
      l,
      ou,
      domain,
      initials,
      title,
      description,
      telephoneNumber,
      labeledURI,
      info,
      homePhone,
      email,
      employeeNumber,
      password,
      quota,
      aliases
    } = userInfo;

    if (parseFloat(quota) <= 0 || 
        quota.match(/^\./) ||
        quota.match(/\.$/)    
    )
    {
      dispatch(showAlertAction(i18next.t("invalid_quota_value"), "error"));
      return
    }

    // let passwordInfo = getState().users.passwordInfo

    if (password) {
      // if (password.length < 6 || password.length > 128) {
      //   dispatch(showAlertAction(i18next.t("invalid_password_length"), "error"));
      //   return
      // }
      if (password.match(/[а-яА-Я]/g)) {
        dispatch(showAlertAction(i18next.t("users_page.pass_req") + " " + i18next.t("password_only_latin"), "error"));
        return
      }
      // if (password.replace(/[^0-9]/g, "").length < 1) {
      //   dispatch(showAlertAction(i18next.t("password_must_contain_numbers"), "error"));
      //   return
      // }  
      // if (((password.match(/[A-ZА-Я]/g) || []).length === 0 || (password.match(/[a-zа-я]/g) || []).length === 0)
      //   // &&
      //   // passwordInfo.needs_both_upper_and_lower
      // ) {
      //   dispatch(showAlertAction(i18next.t("password_lowercase_uppercase"), "error"));
      //   return
      // }
      // if (password.match(/(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g)) {
      //   dispatch(showAlertAction(i18next.t("no_emoji"), "error"));
      //   return
      // }
      // if (password.replace(/[\w]+/g, "").length < passwordInfo.min_special_characters) {
      //   dispatch(showAlertAction(i18next.t("special_characters"), "error"));
      //   return
      // }

      // if (password.replace(/[\w]+/g, "").length < 3) {
      //   dispatch(showAlertAction(i18next.t("special_characters"), "error"));
      //   return
      // }
      // if (password.match(/[^a-zA-Z0-9.-_!@#$%^&*()]/g,"") || password.match(/[\[\]/]/g,"" )|| password.match(/[{}]/g,"" )){
      // dispatch(showAlertAction(i18next.t("invalid_special_characters_password"), "error"));
      // return
      // }
    }
    
    const avatar = userInfo.croppedAvatar
    let avatarForm = null
    quota = Number(quota) * 1048576
    if (avatar){
        avatarForm = new FormData()
        avatarForm.append("file", avatar)
    }
    let alias = aliases.map(
      (alias) => `"${alias.email}@${alias.domain}"`
    );
    alias = `[${alias.join(",")}]`;
    let body = avatarForm
    let mail = `${email}@${domain}`
    const formData = new FormData();   
    if (typeof l === "string" & l !== '') {formData.set("l", l)} 
    if (typeof title === "string" & title !== '') {formData.set("title", title)} 
    if (typeof ou === "string" & ou !== '') {formData.set("ou", ou)} 
    if (typeof description === "string" & description !== '') {formData.set("description", description)} 
    if (typeof telephoneNumber === "string" & telephoneNumber !== ''){formData.set("telephoneNumber", telephoneNumber)} 
    if (typeof homePhone === "string" & homePhone !== '') {formData.set("homePhone", homePhone)} 
    if (typeof info === "string" & info !== '') {formData.set("info", info)} 
    formData.set("cn", cn);
    formData.set("sn", sn);
    if (typeof initials === "string" & initials !== '') {formData.set("initials", initials)} 
    // formData.set("userPassword", password);
    // if (password !== "") { formData.set("userPassword", password) } 
    if (password) {
      formData.set("reset_password", "hard");
      formData.set("userPassword", password);
    }
    formData.set("employeeNumber", employeeNumber) 
    if (alias && alias !=="[]") formData.set("alias", alias) 
    formData.set("quota", Math.floor(quota));
    dispatch(updatingUser())    
    return axios.patch(
      Config.editDeleteUserUrl(
        mail,
        // cn,
        // sn,
        // l,
        // ou,
        // initials,
        // title,
        // description,
        // telephoneNumber,
        // labeledURI,
        // info,
        // homePhone,
        
        // employeeNumber,
        // userPassword,
        // alias,
        // quota
      ),
      formData,
      // ,
      {
        headers: {"isAdmin": "webAdminSolo"},
      }
    )
    .then((response) => {
      if (parseInt(response.status / 200) === 1) {         
        return dispatch(updatedUser());
      }
    })
    .catch((response) => {   
      if (response.status === 409) {   
        if (response.data.extend) {
          if ("min_length" in response.data.extend) {
            dispatch(showAlertAction((i18next.t("users_page.pass_req") + " " + i18next.t("users_page.min_length") + " " + response.data.extend.min_length), "error"))
            return dispatch({ type: PREVENT_USER_ADD })
          }
          if ("max_length" in response.data.extend) {
            dispatch(showAlertAction((i18next.t("users_page.pass_req") + " " + i18next.t("users_page.max_length") + " " + response.data.extend.max_length), "error"))
            return dispatch({ type: PREVENT_USER_ADD })
          }
          if ("wrong_symbol" in response.data.extend) {
            dispatch(showAlertAction((i18next.t("users_page.pass_req") + " " + i18next.t("users_page.wrong_symbol") + " " + response.data.extend.wrong_symbol), "error"))
            return dispatch({ type: PREVENT_USER_ADD })
          }
          if ("min_digits_count" in response.data.extend) {
            dispatch(showAlertAction((i18next.t("users_page.pass_req") + " " + i18next.t("users_page.min_digits_count") + " " + response.data.extend.min_digits_count), "error"))
            return dispatch({ type: PREVENT_USER_ADD })
          }
          if ("min_lowercase_letter_count" in response.data.extend) {
            dispatch(showAlertAction((i18next.t("users_page.pass_req") + " " + i18next.t("users_page.min_lowercase_letter_count") + " " + response.data.extend.min_lowercase_letter_count), "error"))
            return dispatch({ type: PREVENT_USER_ADD })
          } if ("min_uppercase_letter_count" in response.data.extend) {
            dispatch(showAlertAction((i18next.t("users_page.pass_req") + " " + i18next.t("users_page.min_uppercase_letter_count") + " " + response.data.extend.min_uppercase_letter_count), "error"))
            return dispatch({ type: PREVENT_USER_ADD })
          } if ("min_spec_symbols_count" in response.data.extend) {
            dispatch(showAlertAction((i18next.t("users_page.pass_req") + " " + i18next.t("users_page.min_spec_symbols_count") + " " + response.data.extend.min_spec_symbols_count), "error"))
            return dispatch({ type: PREVENT_USER_ADD })
          }
          if (Object.values(response.data.extend).includes("must be greater than or equal to 0")) {
            let paramName = Object.keys(response.data.extend).find(key => response.data.extend[key] === "must be greater than or equal to 0")
            dispatch(showAlertAction(`${i18next.t("users_page.parameter")} ${paramName} : ${i18next.t("users_page.geater_zero")}`, "error"))
            return dispatch({ type: PREVENT_USER_ADD })
          }
          if (Object.values(response.data.extend).includes("must be less than or equal to 65535")) {
            let paramName = Object.keys(response.data.extend).find(key => response.data.extend[key] === "must be less than or equal to 65535")
            dispatch(showAlertAction(`${i18next.t("users_page.parameter")} ${paramName} : ${i18next.t("users_page.less_than")}`, "error"))
            return dispatch({ type: PREVENT_USER_ADD })
          }
          if (response.data.extend.existing_aliases) {
            dispatch(preventUserAdd(response.data.error.code, response.data.extend.existing_aliases))
            return dispatch({ type: PREVENT_USER_ADD })
          }
        }   
             
        if (response.data.error.code && response.data.error.code === 101) {
          dispatch(showAlertAction(i18next.t("users_page.user_already_exists"), "error"));
          return dispatch({ type: PREVENT_USER_ADD })
        } if (response.data.error.code && response.data.error.code === 102) {
          dispatch(showAlertAction(i18next.t("users_page.email_already_exists"), "error"));
          return dispatch({ type: PREVENT_USER_ADD })
        }
        dispatch(showAlertAction(i18next.t("users_page.error409"), "error"));
        return dispatch({ type: PREVENT_USER_ADD })
      }   
      if (response.status === 400 ) {          
        dispatch(showAlertAction(i18next.t("users_page.required_params"), "error"))
        return dispatch({type: PREVENT_USER_ADD})   
      }
      if (response.status === 401 ) {          
        dispatch(showAlertAction(i18next.t("users_page.old_token"), "error"))
        return dispatch({type: PREVENT_USER_ADD})  
      }
      if (response.status === 403 ) {          
        dispatch(showAlertAction(i18next.t("users_page.no_permission"), "error"))
        return dispatch({type: PREVENT_USER_ADD})    
      }   
      else if (response.status === 500 ) {          
          dispatch(showAlertAction(i18next.t("Error"), "error"))
          return dispatch({type: PREVENT_USER_ADD})    
      }         
    });      
  };
};
export const updatingUser = () => ({
  type: UPDATING_USER,
});

export const updatedUser = () => {
  createHashHistory().push("/users");
  return { type: UPDATED_USER };
};
export const cleanSelectedUser = () => ({
  type: CLEAN_SELECTED_USER,
  payload: { selectedUser: "" },
});

export const deleteUser = (userMail) => {
  return function (dispatch) {
    dispatch(deletingUser());
    axios.delete(Config.editDeleteUserUrl(userMail)
    // , {
    //   headers: { AUTHORIZATION: localStorage.getItem("token") },
    // }
    )
      .then(() => {
        dispatch(deletedUser(userMail));
        dispatch(getUsers(1));
      })
      .catch(() => {
        // debugger;
        localStorage.setItem("token", "");
        localStorage.setItem("tenant", "");
        let history = createHashHistory();
        history.push("/login");
      });
  };
};

export const deletingUser = () => ({
  type: DELETING_USER,
  payload: { deletingUser: true },
});

export const deletedUser = (userMail) => ({
  type: DELETED_USER,
  payload: { deletingUser: false, userMail },
});
