import { 
    BEGIN_TENANTS_FETCH,  
    SUCCESS_TENANTS_FETCH,
    ADDING_NEW_DOMAIN,
    ADDED_NEW_DOMAIN,
    PREVENT_DOMAIN_ADD,
    CLEAN_SELECTED_USER,
    BEGIN_DOMAINS_FETCH,
    SUCCESS_DOMAINS_FETCH,
    UPDATING_DOMAIN,
    UPDATED_DOMAIN,
    DELETED_DOMAIN,
    DELETING_DOMAIN,    
  } from "../constants/DomainsConstants";
import { Config } from "../configs";
import { createHashHistory } from "history";
import { showAlertAction } from "./AlertsActions";
import i18next from "i18next";
import axios from "axios";
import { enqueueSnackbar } from 'notistack'

export const getTenants = () => {
  return function (dispatch) {
    dispatch(beginTenantsFetch());  
    return axios.get(Config.tenantsUrl())
      .then((response) => {
        if (response.status === 200) {         
            dispatch(successTenantsFetch(response.data));           
        } else {
          return dispatch(showAlertAction("Error", "error"));
        }
      })
  };
};

export const beginTenantsFetch = () => ({
  type: BEGIN_TENANTS_FETCH,
  payload: { tenantsFetching: true },
});

export const successTenantsFetch = (tenants) => ({
  
  type: SUCCESS_TENANTS_FETCH,
  payload: { tenantsFetching: false, tenants },
});

export const getDomains = () => {
    return function (dispatch) {
      dispatch(beginDomainsFetch());  
      return axios.get(Config.domainsUrl())
        .then((response) => {
          if (response.status === 200) {           
            dispatch(successDomainsFetch(response.data, response.status));
          }
        })
        .catch((response) => {
            if (!response.data) return dispatch(successDomainsFetch([], "unknown"));
            dispatch(successDomainsFetch(response.data, response.status));
          })        
    };
};
  
export const beginDomainsFetch = () => ({
    type: BEGIN_DOMAINS_FETCH,
    payload: { domainsFetching: true },
  });
  
export const successDomainsFetch = (domains, status) => ({
    type: SUCCESS_DOMAINS_FETCH,
    payload: { domainsFetching: false, domains, status },
  });

export const removeFetchStatus = () => { 
    return ({    
      type: SUCCESS_DOMAINS_FETCH,
      payload: { domainsFetching: false, domains: [], status: ""  },
    });
};

export const addNewDomain = (domain, description) => {    
    return function (dispatch) {  
      const formData = new FormData();
      if (description) formData.set("description", description)
      dispatch(addingNewDomain());    
        return axios
          .put(Config.domainsAddEditDelUrl(domain), formData)
          .then((response) => {
            if (parseInt(response.status / 200) == 1) {
              let options = {
                type: "small",
                variant: "success",
              };
              let message = `${i18next.t("domains_page.domain")} ${domain} ${i18next.t("domains_page.added")}`;
              enqueueSnackbar(message, options);
              dispatch(addedNewDomain());
            }
          })
          .catch((response) => {
            if (!response.data) return dispatch(errorNewDomain("unknown", ""));
            return dispatch(
              errorNewDomain(response.status, response.data?.error?.code)
            );
            //  if (response.status === 403) {
            //     return response.json().then((json) => {
            //       if (json.message === "User session was removed") {
            //         localStorage.setItem("token", "");
            //         localStorage.setItem("tenant", "");
            //         let history = createHashHistory();
            //         history.push("/login");
            //       } else {
            //         dispatch(oldToken)
            //       }
            //     })
            //   }

            //   else if (response.status === 400) {
            //     response.json().then((errorBody) => {
            //       if (errorBody.message === "Domain is invalid") dispatch(showAlertAction(i18next.t("invalid_domain_value"), "error"))
            //     })
            //   }

            //   dispatch(addedNewDomain());
            //   dispatch(getDomain());
            //   let history = createHashHistory();
            //   history.push("/domains");

            //   localStorage.setItem("token", "");
            //   localStorage.setItem("tenant", "");
            //   let history = createHashHistory();
            //   history.push("/login");
          });
    };
  };
  
export const addingNewDomain = () => ({
    type: ADDING_NEW_DOMAIN,
  });
  
export const addedNewDomain = () => {
  createHashHistory().push("/domains")
  return {type: ADDED_NEW_DOMAIN }
};

export const errorNewDomain = (status, code) => ({
  type: ADDED_NEW_DOMAIN,
  payload: { status: status, code: code },
});

export const removeAddStatus = () => { 
  return ({    
    type: ADDED_NEW_DOMAIN,
    payload: { add_status: "" },
  });
};



export const deleteDomain = (domain) => {
  return function (dispatch) {
    dispatch(deletingDomain());
    axios
      .delete(Config.domainsAddEditDelUrl(domain))
      .then((response) => {
        if (parseInt(response.status / 200) === 1) {        
          dispatch(deletedDomain(response.status));
          dispatch(getDomains());
          let options = {
            type: "small",
            variant: "success",
          };
          let message = `${i18next.t("domains_page.domain")} ${domain} ${i18next.t("domains_page.deleted")}`;
          enqueueSnackbar(message, options);
        }
      })
      .catch((response) => {
        if (!response.data) return dispatch(deletedDomain("unknown"))
        return dispatch(deletedDomain(response.status));
      });
  };
};
  
  export const deletingDomain = () => ({
    type: DELETING_DOMAIN,
    payload: { deletingDomain: true },
  });
  
export const deletedDomain = (status) => ({
    type: DELETED_DOMAIN,
    payload: { deletingDomain: false, status: status},
  });  

export const updateDomain = (domain, description, associatedDomain) => {  
  return function (dispatch) {
    const formData = new FormData();   
    if (associatedDomain === true || associatedDomain === "true") {     
      formData.set("associatedDomain", "True");
    } else formData.set("associatedDomain", "False");    
    if (description) formData.set("description", description);
    dispatch(updatingDomain());
    axios
      .patch(Config.domainsAddEditDelUrl(domain), formData)
      .then((response) => {
        if (parseInt(response.status / 200) === 1) {
          dispatch(updatedDomain(domain, response.status));
          dispatch(getDomains());
        }
      })
      .catch((response) => {
        if (!response.data) return dispatch(errorUpdDomain("unknown"));
        return dispatch(
          errorUpdDomain(response.status, response.data?.error?.code)
        );
      });
  };
};

export const updatingDomain = () => ({
  type: UPDATING_DOMAIN,
  payload: { updatingDomain: true },
});
  
  export const updatedDomain = (domain, result) => {
    createHashHistory().push("/domains")
    return {
      type: UPDATED_DOMAIN,
      payload: { updatingDomain: false, domain, result },
    }
   
  };

  export const errorUpdDomain = (status, code) =>
  ({
    type: UPDATED_DOMAIN,
    payload: { status: status, code: code },
  });
