import {
    BEGIN_TENANTS_FETCH,  
    SUCCESS_TENANTS_FETCH,
    ADDING_NEW_DOMAIN,
    ADDED_NEW_DOMAIN,
    PREVENT_DOMAIN_ADD,
    // CLEAN_SELECTED_USER,
    BEGIN_DOMAINS_FETCH,
    SUCCESS_DOMAINS_FETCH,
    UPDATING_DOMAIN,
    UPDATED_DOMAIN,
    DELETED_DOMAIN,
    DELETING_DOMAIN,    
  } from "../constants/DomainsConstants";
  
const initialState = {
    tenants: [],
    tenantsFetching: false,
    domainsFetching: false,
    domains: [],   
    domain: {},
    domainFetching: false,
    selectedDomain: "",        
    addingNewDomain: false,
    updatingDomain: false,
    deletingDomain: false,
    delete_status: "",
    domains_status: "",
    add_status: "",
    add_code: "",
    upd_status: "",
    upd_code: ""
  };
  
  export const domains = (state = initialState, action = {}) => {
    switch (action.type) {
     
      // case CLEAN_SELECTED_USER:
      //   return Object.assign({}, state, {
      //     selectedUser: action.payload.selectedUser,
      //   });
      case BEGIN_TENANTS_FETCH:
        return Object.assign({}, state, {
          tenantsFetching: action.payload.tenantsFetching,
        });
      case SUCCESS_TENANTS_FETCH:       
        return Object.assign({}, state, {
          tenantsFetching: action.payload.tenantsFetching,
          tenants: action.payload.tenants.results,
        });
      
      case BEGIN_DOMAINS_FETCH:
        return Object.assign({}, state, {
          domainsFetching: action.payload.domainsFetching,
        });
      case SUCCESS_DOMAINS_FETCH:       
        return Object.assign({}, state, {
          domainsFetching: action.payload.domainsFetching,
          domains: action.payload.domains.results,
          domains_status: action.payload.status
        });
      case ADDING_NEW_DOMAIN:
        return Object.assign({}, state, {
          addingNewDomain: true,
        });
      case ADDED_NEW_DOMAIN:        
        return Object.assign({}, state, {          
          addingNewDomain: false,
          add_status: action.payload.status,
          add_code: action.payload.code,
        });
        case PREVENT_DOMAIN_ADD: 
        return Object.assign({}, state, {
            addingNewDomain: false,
            updatingDomain: false
        })
      case UPDATING_DOMAIN:
        return Object.assign({}, state, {
          updatingDomain: true,
        });
      case UPDATED_DOMAIN:         
        return Object.assign({}, state, {
          updatingDomain: false,
          upd_status: action.payload.status,
          upd_code: action.payload.code,
        });
      case DELETING_DOMAIN:
        return Object.assign({}, state, {
          deletingDomain: action.payload.deletingDomain,
        });
      case DELETED_DOMAIN:          
        return Object.assign({}, state, {
          deletingDomain: action.payload.deletingDomain,
          delete_status: action.payload.status,
        });
      default:
        return state;
    }
  };
  