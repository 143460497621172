import React from 'react';
// import PropTypes from 'prop-types';
import {Box, FormControlLabel, Checkbox} from '@material-ui/core';
import './PsnCheckbox.scss';

const EmptyCheck = () => {
    return <div className='checkbox emptyCheck'></div>
}

const Checked = () => {
    return <div className='checkbox checked'></div>
}

/**
 * Кастомный компонент checkbox
 * @param param
 * @param param.label - Описание чекбокса
 * @param param.disabled - Состояние активности чекбокса
 * @param param.props - Оставшиеся свойства MUI Checkbox
 * */
const PsnCheckbox = ({label, disabled = false, labelPlacement='end', ...props}) => {
    return <Box className="psnCheckboxRoot">
            <FormControlLabel
            control={
                <Checkbox
                    icon={<EmptyCheck/>}
                    checkedIcon={<Checked/>}
                    {...props}
                />
            }
            label={label}
            disabled={disabled}
            labelPlacement={labelPlacement}
        />
    </Box>
}

// PsnCheckbox.propTypes = {
//     label: PropTypes.string,
//     disabled: PropTypes.bool,
//     labelPlacement: PropTypes.oneOf(['start', 'end', 'top', 'bottom']),
//     props: PropTypes.element
// }

export default PsnCheckbox;