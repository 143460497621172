import {
  AUTHORIZE,
  NO_LOGIN_OR_PASSWORD_AUTH,
  BEGIN_LOGIN,
  SUCCESS_LOGIN,
  UNSUCCESS_LOGIN,
  SELECT_LANGUAGE,
  CLEAN_SELECTED_TENANTMENU,
  SELECTED_TENANTMENU
} from "../constants/LoginConstants";

const initialState = {
  noLogin: false,
  noPassword: false,
  isAuthing: false,
  selectedLanguage: localStorage.getItem("language"),
  selectedTenantMenu: {}
};

export const auth = (state = initialState, action = {}) => {
  switch (action.type) {
    case AUTHORIZE:
      return Object.assign({}, state, {});
    case NO_LOGIN_OR_PASSWORD_AUTH:
      return Object.assign({}, state, {
        noLogin: action.payload.noLogin,
        noPassword: action.payload.noPassword,
      });
    case BEGIN_LOGIN:
      return Object.assign({}, state, {
        noLogin: false,
        noPassword: false,
        isAuthing: true,
      });
    case SUCCESS_LOGIN:
      return Object.assign({}, state, {
        isAuthing: false,
        token: action.payload.token,
      });
    case UNSUCCESS_LOGIN:
      return Object.assign({}, state, {
        isAuthing: false,
      });
    case SELECT_LANGUAGE:
      return Object.assign({}, state, { selectedLanguage: action.payload });
    case SELECTED_TENANTMENU:     
      return Object.assign({}, state, {
        selectedTenantMenuItem: action.payload.menuItem,
        selectedTenantMenuTenant: action.payload.tenant
      });
    case CLEAN_SELECTED_TENANTMENU:
      return Object.assign({}, state, {
        selectedTenantMenu: action.payload.selectedMaillist,
      });
    default:
      return state;
  }
};
