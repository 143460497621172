import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  makeStyles,
  Slide,
} from "@material-ui/core";
import Info from "../../assets/info-red.svg";
import { Height, NoEncryption } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../styles/Domain.css";
import {
  blue,
  green,
  orange,
  purple,
  red,
  pink,
  deepPurple,
  indigo,
  cyan,
  lightGreen,
} from "@material-ui/core/colors";
import i18next from "i18next";

const useStyles = makeStyles((theme) => ({
  title: {    
    color: "#19252E",   
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "20px",   
  },
  row: {   
    color: "#757C82",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    paddingTop: "0px"
  },
  error_field: {
    marginRight: "12px",
    marginLeft: "-12px",
    maxWidth: "420px",   
    Height: "72px",   
    Radius: "4px",
    Padding: "12px, 8px, 12px, 8px",
    backgroundColor: "#FFF0F0",
    display: "flex"
  },
  error_text: {
    maxWidth: "293px",   
    Height: "48px", 
    color: "#000",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    fontStyle: "normal"
  },
  mainButton: {
    backgroundColor: "#E2333A !important",
    color: "white",
    width: "78px",
    height: "32px",
    // marginRight: "4px",
    textTransform: "none",
    fontWeight: "525",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.35px",
    "&:hover": {
      backgroundColor: "#BB1B1F !important",
      color: "white",
    }
  },
  cancelButton: {
    fontWeight: "525",
    width: "75px",
    height: "32px",
    textTransform: "none",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.35px",
    border: "1px solid #C0C0C0",   
    "&:hover": {
      backgroundColor: "#EDEEEF !important",
      color: "#19252E",
    }
  }, 
  dialog: {
    backgroundColor: "#FFFFFF",
    width: "444px",
    minHeight: "188px",
    paddingTop: "12px",
    paddingLeft: "24px",
    paddingBottom: "24px",
    borderRadius: "4px",   
    boxShadow: "0px 0px 12px rgba(25, 37, 46, 0.24) !important"  
  }, 
  dialogCont: {
    backgroundColor: "rgba(0, 0, 0, 0.40)",
  },
  footer: {    
    paddingLeft: "24px",
    justifyContent: "flex-start",
    // paddingBottom: "0px",
  },
  spinner: {    
    position: "absolute",
    left: "206px",
    top: "46%",
    color: "#FF962E !important",   
  },
  }));

const DeleteDomen = ({
  open,
  setOpen,
  deletingDomain,
  result,
  title,
  body,
  mainButton,
  cancelButton, 
  ...props
}) => {
  const classes = useStyles();
  return ( 
   
      
      <Dialog    
      open={open != ""}
      onClose={() => setOpen(false)}        
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="false"
      classes={{ paper: classes.dialog, container: classes.dialogCont  }}
      // classname = "domainDeleteDialog"
      >
     <div className={classes.error_field} style={{display: (result && result !== 200) ? "flex" : "none"}}> 
          <img
            style={{ marginRight: "8px" }}
            src={Info}
            alt={Info}
            width="24px"
            height="24px"
          />
          <div className={classes.error_text}>{i18next.t("domains_page.delete_error")}</div>
        </div>
      <DialogTitle
        style={{ opacity: deletingDomain ? "40%" : "100%", paddingBottom: "0px !important"  }}              
      >       
          <div className={classes.title}>{title}</div>  
       
      </DialogTitle>
        <DialogContent className={classes.body}>
          <CircularProgress className={classes.spinner} style={{display: deletingDomain ? "block" : "none"}} />
        <DialogContentText className={classes.row} style={{ opacity: deletingDomain ? "40%" : "100%", }}>{body}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.footer}>
          <Button className={classes.mainButton}
          style={{opacity: deletingDomain ? "40%" : "100%"  }}
            disableRipple
            // disabled={deletingDomain}
            onClick={              
              mainButton.action           
            }
            autoFocus>
          {i18next.t("domains_page.delete")}
        </Button>
        <Button
          disableRipple
          // variant="outlined"
          className={classes.cancelButton}
          onClick={cancelButton.action}
          disabled={deletingDomain}
          style={{ opacity: deletingDomain? "40%" : "100%"  }}
        >
          {i18next.t("domains_page.cancel")}
        </Button>
          </DialogActions>
         
      </Dialog>
      
     
  );
};

export default DeleteDomen
    