import React, { useState, useEffect } from "react";
import { getSelectedTenantMenu, cleanSelectedTenantMenu } from "../../actions/LoginActions";
import { useDispatch } from "react-redux";
import { NavLink as RouterLink } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText,  List, Collapse} from "@material-ui/core";
import PropTypes from "prop-types";
import "../../styles/Sidebar.css";
import SidebarLink from "./SidebarLink";
import Up from "../../assets/up.svg";
import i18next from "i18next";

const TenantLine = ({ ...props }) => {
  const { icon, primary, to, selectedTenantMenuItem, selectedTenantMenuTenant, tenant } = props;
  const [open, setOpen] = useState(localStorage.getItem(`${tenant[0]}`) ? true : false)
  const dispatch = useDispatch()
  const sidebarItems = [
    {
      title: i18next.t("domains_page.mail_domains"),
      path: `/domains`,      
      // icon: <img src={UsersIcon} alt={UsersIcon} />,
    },
    {
      title: i18next.t("users"),
      path: "/users",
      // icon: <img src={UsersIcon} alt={UsersIcon} />,
    },
    {
      title: i18next.t("resource_groups"),
      path: "/resource_groups",
      // icon: <img src={ResourceGroupsIcon} alt={ResourceGroupsIcon} />,
    },
    {
      title: i18next.t("mailLists.mailGroups"),
      path: "/maillists",
      // icon: <img src={MaillistsIcon} alt={MaillistsIcon} />,
    },
  ];
  
  const handleArrow = () => {
    setOpen(!open);
    if (!open) {      
      localStorage.setItem(`${tenant[0]}`, tenant[0])
    } 
    else localStorage.removeItem(`${tenant[0]}`)   
  };

  return (
    <div>
      <div className="tenantRow">
        <div className="tenantName">{tenant}</div>
        <img
          className={open ? "upArrow" : "upArrow-rotate"}
          src={Up}
          alt={Up}
          width="24px"
          onClick={handleArrow}
        />
      </div>
      <Collapse in={!open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {sidebarItems.map((sidebarItem, index) => (
            <SidebarLink              
              to={sidebarItem.path}
              primary={sidebarItem.title}     
              selectedTenantMenuItem={selectedTenantMenuItem}    
              selectedTenantMenuTenant = {selectedTenantMenuTenant}
              onClick={() => dispatch(getSelectedTenantMenu(localStorage.getItem("tenant"), sidebarItem.title))}
              tenant = {tenant[0]}
            />
          ))}
        </List>
      </Collapse>
     
    </div>
  );
};

SidebarLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default TenantLine;