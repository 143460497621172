import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Drawer, CssBaseline, List, Tooltip, Collapse, } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import SidebarLink from "./SidebarLink";
import TenantLine from "./TenantLine"
import "../../styles/Sidebar.css";
import LogoLeft from "../../assets/logo.svg";
import Logout from "../../assets/exit.svg";
import { logout, getSelectedTenantMenu, cleanSelectedTenantMenu } from "../../actions/LoginActions";
import { getTenants } from "../../actions/DomainsActions";
import {ReactComponent as ExternalLink}  from '../../assets/externalLink.svg'
import UsersIcon from "../../assets/person.svg";
import ResourceGroupsIcon from "../../assets/resources.svg";
import i18next from "i18next";
import ChangeLanguage from "../ChangeLanguage/ChangeLanguage";
import MaillistsIcon from "../../assets/maillists.svg";
import { createHashHistory } from "history";

const drawerWidth = 240;

const mapStateToProps = (state) => ({
  tenants: state.domains.tenants,
  tenantsFetching: state.domains.tenantsFetching,
  selectedTenantMenuItem: state.auth.selectedTenantMenuItem,
  selectedTenantMenuTenant: state.auth.selectedTenantMenuTenant
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  getTenants: () => dispatch(getTenants()),
  getSelectedTenantMenu: (tenant, menuItem) => dispatch(getSelectedTenantMenu(tenant, menuItem)),
});

const sidebarItems = [
  {
    title: i18next.t("domains_page.mail_domains"),
    path: "/domains",
    // icon: <img src={UsersIcon} alt={UsersIcon} />,
  },
  {
    title: i18next.t("users"),
    path: "/users",
    // icon: <img src={UsersIcon} alt={UsersIcon} />,
  },
  {
    title: i18next.t("resource_groups"),
    path: "/resource_groups",
    // icon: <img src={ResourceGroupsIcon} alt={ResourceGroupsIcon} />,
  },
  {
    title: i18next.t("mailLists.mailGroups"),
    path: "/maillists",
    // icon: <img src={MaillistsIcon} alt={MaillistsIcon} />,
  },
];

const styles = (theme) => ({
  root: {    
    
    display: "flex",
    backgroundColor: "transparent !important",
  },
  drawer: {
    marginLeft: "56px",
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: "transparent !important",
  },
  drawerPaper: {    
    width: drawerWidth,
    borderRight: 0,
    backgroundColor: "transparent !important",
    marginLeft: "56px",
  },
  content: {
    flexGrow: 1,
  },
  changeLang: {
    marginTop: "auto",
    marginBottom: "40px",
    marginLeft:"2vw",
    display:"flex",
    width: "300px",
    height: "400px",
    // backgroundColor:"brown",
  },
});

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      langWindow: false,
      tenants: [],
      arrow: false      
    };
   
    this.history = createHashHistory();
  }

  closeLangWindow() {
    this.setState({ langWindow: false });
  }

  

  async componentDidMount() {    
    if (localStorage.getItem("role") === "3") {
      await this.props.getTenants()
      this.setState({tenants: this.props.tenants})  
    }
   
  }

  render() {
    const { classes, logout } = this.props;
    
    return (
      <div className={classes.root}>
        <div className="greyBar">
          <img
            className="logoImgLeftBar"
            src={LogoLeft}
            alt={LogoLeft}
            width="160px"
          />
          <div className="help">
            <ChangeLanguage positionStyle={this.props.classes.changeLang} />

            <div className="logoutButton" onClick={logout}>
              <Tooltip title={i18next.t("logout")} placement="right">
                <img
                  style={{ height: "30px" }}
                  className="logoutBtn"
                  src={Logout}
                  alt={Logout}
                />
              </Tooltip>
            </div>
          </div>
        </div>
        <CssBaseline />
        {localStorage.getItem("role") === "3" ? (
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <div className="mainMenu">
              {/* <div className="tenantName">
                {i18next.t("domains_page.comon_settings")}
              </div> */}
              {this.state.tenants.map((el) => (
                <div                                    onClick={() => {
                    localStorage.setItem("tenant", Object.keys(el));
                  }}
                  style={{ marginBottom: "12px" }}
                >
                  <TenantLine tenant={Object.keys(el)} selectedTenantMenuItem = {this.props.selectedTenantMenuItem} selectedTenantMenuTenant = {this.props.selectedTenantMenuTenant}/>
                </div>
              ))}
            </div>
          </Drawer>
        ) : (
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor="left"
          >
            <div className="mainMenu">
              <div className="tenantName">{localStorage.getItem("tenant")}</div>
              {sidebarItems.map((sidebarItem, index) => (
                <SidebarLink                  
                  key={index}
                  to={sidebarItem.path}
                  selectedTenantMenuItem={this.props.selectedTenantMenuItem}
                  selectedTenantMenuTenant={this.props.selectedTenantMenuTenant}
                  tenant = {localStorage.getItem("tenant")}
                  primary={sidebarItem.title}                  
                  // icon={sidebarItem.icon}
                />
              ))}
              {!!window.PGS_ADMIN_URL && window.PGS_ADMIN_URL !== "" && 
                <SidebarLink
                  onClick={()=> window.open(window.PGS_ADMIN_URL, '_blank')}
                  // to={window.PGS_ADMIN_URL}
                  primary={i18next.t('administration')}
                  className="sidebar_pgs-link"
                  icon={<ExternalLink />}
                />}
            </div>
          </Drawer>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Sidebar))
);
