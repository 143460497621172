import React from "react";
import {
  TextField,
  Button,  
  Tooltip, 
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { updateDomain} from "../../actions/DomainsActions.js";
import { connect } from "react-redux";
import { createHashHistory } from "history";
import CircularProgress from "@material-ui/core/CircularProgress";
import i18next from "i18next";
import BackArrow from "../../assets/backArrow.svg";
import InfoRed from "../../assets/info-red.svg";
import "../../styles/Domain.css";
import { ThumbUpSharp } from "@material-ui/icons";

const mapStateToProps = (state) => ({ 
  upd_status: state.domains.upd_status,
  upd_code: state.domains.upd_code,
  updatingDomain: state.domains.updatingDomain
});

const mapDispatchToProps = (dispatch) => ({  
  updateDomain: (domain, description, associatedDomain) => dispatch(updateDomain(domain, description, associatedDomain)),
});

const styles = (theme) => ({
  
  infoForm: {
    marginBottom: "10px",
    "& > *": {
      margin: "10px",
    },
  },
  buttonsBlock: {
    "& > *": {
      margin: "10px",
    },
  },
  spinner: {    
    position: "absolute",
    left: "500px",
    top: "276px",
    color: "#FF962E !important",   
  },  
  addDomainBtn: {
    width: "105px",
    height: "32px",
    paddingLeft:"16px",
    marginRight: "12px",
    color: "#19252E",
    backgroundColor: "rgba(68, 187, 255, 0.56)",
    // fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.35px",
    border: "1px solid rgba(25, 37, 46, 0.4)",
    textTransform: "none",
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: "rgba(68, 187, 255, 0.86) !important",
      color: "#19252E",
      border: "1px solid rgba(25, 37, 46, 0.08)",
    },
    "&:disabled": {
      backgroundColor: "rgba(25, 37, 46, 0.08) !important",
      color: "#A3A8AB",
      border: "1px solid rgba(25, 37, 46, 0.08)",
      cursor: 'pointer !important',
    },
    // "&:selected": {
    //   backgroundColor: "#BFDDF3 !important",
    //   color: "#0079D0",
    //   // border: "1px solid rgba(25, 37, 46, 0.08)",
    // },
    // "&:active": {
    //   backgroundColor: "#BFDDF3 !important",
    //   color: "#0079D0",
    //   // border: "1px solid rgba(25, 37, 46, 0.08)",
    // },
    },
   
    cancelButton: {
        paddingLeft:"16px",
        color: "#19252E",
        backgroundColor: "white !important",
        fontWeight: "525",
        width: "84px",
        height: "32px",
        textTransform: "none",
        fontSize: "14px",
        lineHeight: "16px",
        letterSpacing: "0.35px",
        border: "1px solid rgba(25, 37, 46, 0.4)",
        "&:hover": {
          backgroundColor: "rgba(25, 37, 46, 0.08) !important", 
          color: "#19252E",
          border: "1px solid rgba(25, 37, 46, 0.4)"
        },
        // "&:active": {
        //   backgroundColor: "#BFDDF3 !important",
        //   color: "#0079D0",
        //   border: "1px solid #0079D0"
        // }
      },
  RoundTooltip: {    width: "32px",
    height: "32px",
    marginTop: "16px",
    "&:hover": {
      backgroundColor: "rgba(25, 37, 46, 0.08); !important",
    },
    "&:active": {
      backgroundColor: "rgba(25, 37, 46, 0.16); !important",
    },
  },
  
});

class DomainForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      domain: localStorage.getItem("domainCur"),
      description: localStorage.getItem("description") ? localStorage.getItem("description") : "",
      associatedDomain: localStorage.getItem("associatedDomain")
    };
    this.history = createHashHistory();
  }

  handleDomainSubmit = (e) => {   
    e.preventDefault();      
    this.props.updateDomain(
      this.state.domain,
      this.state.description,
      this.state.associatedDomain,
    );   
  };

  handleClickOpen = () => {
    this.setState({ errorWindow: true });
  };

  handleClose = () => {
    this.setState({ errorWindow: false });
  };

  nameChangeHandler = (e, v) => {
    // if (e.target.value.match(/[^a-zA-Zа-яА-Я0-9_.-\s+]/g)) return
    // if (e.target.value.length > 255) return
    // if (v === "domain") this.setState({ domain: e.target.value });
  if (e.target.value?.length < 1000) {
      this.setState({ description: e.target.value });
    } else return;  
  };

  render() {
    let classes = this.props.classes;   
    return (
      <div className={this.props.updatingDomain ? "NewDomainRootLoading" : "NewDomainRoot"} >
         <CircularProgress className={classes.spinner} style={{display: this.props.updatingDomain ? "flex" : "none"}} />
        <div style={{ display: "flex" }}>
          <div>
            <Tooltip title={i18next.t("back")}>
              <img
                src={BackArrow}
                alt={BackArrow}
                width="24px"
                onClick={(e) => this.history.push("/domains")}
              />
            </Tooltip>
          </div>
          <div
            className="title"
            style={{ marginBottom: "32px", marginLeft: "12px" }}
          >
            {i18next.t("domains_page.edit_domain")}
          </div>
        </div>
        <div className="DomainsAddError" style={{display: ((this.props.upd_status && this.props.upd_status !==200)  ? "flex" : "none")}}>
              <img
                style={{ marginRight: "8px" }}
                src={InfoRed}
                alt={InfoRed}
                width="24px"
                height="24px"
              />
              {i18next.t("domains_page.delete_error")}
            </div>
        <div className="NewDomainTitle2">
          {i18next.t("domains_page.tenant_id")}
        </div>
        <div className="NewDomainField1" style={{ marginBottom: "16px" }}>
          <TextField
            className="NewDomainField1"
            variant="outlined"
            id="outlined-required"
            value={localStorage.getItem("tenant")}
            disabled
          />
        </div>
        <div className="NewDomainTitle2">
          {i18next.t("domains_page.domain_name")}
        </div>
        <div className="NewDomainField1" style={{ marginBottom: "16px" }}>
          <TextField
            disabled
            className="NewDomainField1"
            variant="outlined"
            id="outlined-required"
            value={localStorage.getItem("domainCur")}
            onChange={(e) => {
              this.nameChangeHandler(e, "domain");
            }}
          />          
        </div>
        <div className="NewDomainTitle2">
          {i18next.t("domains_page.description")}
        </div>
        <div className="NewDomainField2" style={{ marginBottom: "20px" }}>
          <TextField
            fullWidth
            multiline            
            className="NewDomainField1"
            variant="outlined"
            id="outlined-required"
            value={this.state.description}
            onChange={(e) => {
              this.nameChangeHandler(e, "description");
            }}
          />
        </div>
        <div>
          <Button
            onClick={this.handleDomainSubmit}
            className={classes.addDomainBtn}
            variant="outlined"
            disabled={
              this.state.description === localStorage.getItem("description")
            }
          >
            {i18next.t("domains_page.save")}
          </Button>
          <Button
            onClick={(e) => this.history.push("/domains")}
            className={classes.cancelButton}
            variant="outlined"
          >
            {i18next.t("domains_page.cancel")}
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DomainForm)
);
