import React from "react";
import { TextField, Button, MenuItem, Select } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { addNewMaillist, removeAddStatus, getMaillists, getSelectedMaillist, } from "../../actions/MaillistsActions.js";
import PsnCheckbox from "./../GeneralElements/PsnCheckbox";
import i18next from "i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getUsers } from "../../actions/UsersActions";
import { getDomains } from "../../actions/DomainsActions";
import { parser } from "../../allFunctions/parser.js";
import { mailParser } from "../../allFunctions/parser.js";
import { createHashHistory } from "history";
import "../../styles/Maillists.css";


const mapStateToProps = (state) => ({
  users: state.users.users,
  domains: state.domains.domains,
  domainsFetching: state.domains.domainsFetching,
  add_status: state.maillists.add_status,  
});

const mapDispatchToProps = (dispatch) => ({
  getMaillists: () => dispatch(getMaillists()),
  addNewMaillist: (mail, alias, displayName, localOnly) =>
    dispatch(addNewMaillist(mail, alias, displayName, localOnly)),
  getUsers: (page, search) => dispatch(getUsers(page, search)),
  getDomains: () => dispatch(getDomains()),
  removeAddStatus: () => dispatch(removeAddStatus()),
  getSelectedMaillist: (displayName) => dispatch(getSelectedMaillist(displayName)),
});

const styles = (theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '4px'      
    },
    '*::-webkit-scrollbar-track': {
      // backgroundColor: "red",
      // width:"4px"
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: "rgba(25, 37, 46, 0.16)",
      // width:"14px",
      // paddingTop: "28px",
      borderRadius: "2px",      
    }
  },  
  root: {
    width: "100%",
    backgroundColor: "white",
    minHeight: "100vh",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
  },
  infoForm: {
    marginBottom: "10px",
    width: "100%",
    padding: "10px",
    maxWidth: "700px",
    "& > *": {
      margin: "10px",
    },
  },
  mainButton: {
    backgroundColor: "rgba(68, 187, 255, 0.56) !important",
    color: "rgba(25, 37, 46, 1)",
    width: "98px",
    height: "32px",
    marginRight: "4px",
    textTransform: "none",
    fontWeight: "525",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.35px",
    border: "1px solid rgba(25, 37, 46, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(68, 187, 255, 0.72) !important",
      color: "black",
      border: "1px solid rgba(25, 37, 46, 0.08)",
    },
    "&:disabled": {
      backgroundColor: "rgba(25, 37, 46, 0.08) !important",
      color: "rgba(163, 168, 171, 1)",
      border: "1px solid rgba(25, 37, 46, 0.08)",
    },
  },
  cancelButton: {
    color: "black",
    fontWeight: "525",
    width: "98px",
    height: "32px",
    textTransform: "none",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.35px",
    border: "1px solid #C0C0C0",
    "&:hover": {
      color: "black",
      backgroundColor: "rgba(25, 37, 46, 0.08) !important",
    },
  },
});

class NewMaillist extends React.Component {
  constructor(props) {
    super(props);
    this.history = createHashHistory();
    this.state = {
      maillistEmail: "",
      maillistAlias: [],
      maillistName: "",
      domain: "",
      maillistAliasAdded: false,
      localOnly: false,
      emailFieldError: false
    };
    this.handleaddNewMaillist = this.handleaddNewMaillist.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  async componentDidMount() { 
    await this.props.removeAddStatus()
    await this.props.getDomains();   
    this.setState({ domain: this.props.domains[0] })    
  }
  componentDidUpdate(prevProps, prevState) {
    if (!this.props.domains) {
      this.props.getDomains();
    }
  }
  async handleaddNewMaillist(e) {
    e.preventDefault();     
     await this.props.addNewMaillist(
        this.state.maillistEmail + "@" + this.state.domain.domain,
        this.state.maillistAlias,
        this.state.maillistName.trim(),
        this.state.localOnly     
     )
    if (!this.props.add_status) {
      this.props.getSelectedMaillist(this.state.maillistName); 
      await this.props.getMaillists();
      this.history.push(`/maillists/${this.state.maillistEmail}@${this.state.domain.domain}`);
    }
    
  }
  
  handleDomainNameChange(e) {
    e.preventDefault();
    if (
      e.target.value.match(/[^/a-zA-ZА-Яа-я0-9_.-\s+]/g) ||
      e.target.value.length > 255
    ) {
      return;
    } else {
      this.setState({ maillistName: parser(e.target.value) });
    }
  }

  handleListEmailChange(e) {   
    e.preventDefault();
    let value = e.target.value;
    const tester = /^[0-9A-Za-z.]+$/;
    if (!value.match(tester) & (value !== "")) {
      this.setState({ emailFieldError: true });
    } else {
      this.setState({ emailFieldError: false });
    }
    this.setState({ maillistEmail: mailParser(value) });
  } 
  
  handleListEmailBlur(e) {
    e.preventDefault();
    let value = e.target.value;
    if (value && /\.$|-$|^\.|^-|(\W\W)/.test(value)) {
      return this.setState({ emailFieldError: true });
    }      
    if (value.length < 1
      // || (value.split(".").find((item) => item.length < 2))
      ) {
      this.setState({ emailFieldError: true });
    } else this.setState({ emailFieldError: false });
  }

  handleCancel(e) {
    e.preventDefault();
    this.history.goBack();
  }

  render() {
    let classes = this.props.classes;
    return (
      <div className={classes.root}>
        <form className={classes.infoForm} noValidate autoComplete="off">
          <div className="newListTitle" style={{ marginBottom: "32px" }}>
            {i18next.t("mailLists.new_group")}
          </div>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ marginLeft: "0px" }}>
              <TextField
                style={{ width: "247px" }}
                id="standard-name"
                name="domainName"
                label={i18next.t("mailLists.group_name")}
                value={this.state.maillistName}
                onChange={(e) => this.handleDomainNameChange(e)}
              />
            </div>
            <div style={{ marginLeft: "24px" }}>
              <TextField
                style={{ width: "247px" }}
                id="standard-mail"
                name="domainEmail"
                label={i18next.t("mailLists.group_email")}
                value={this.state.maillistEmail}
                onChange={(e) => this.handleListEmailChange(e)}
                onBlur={(e) => this.handleListEmailBlur(e)}
              />
            </div>
            <div
              style={{
                marginLeft: "5px",
                marginRight: "7px",
                paddingBottom: "7px",
              }}
            >
              @
            </div>
            <div style={{ marginTop: "13px" }}>
              <Select
                style={{ width: "247px" }}
                select
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                }}
                // className="domainCellMaillist"
                value={this.state.domain ? this.state.domain : this.props.domains[0]}
                disabled={this.props.domainsFetching}
                onChange={(e) => this.setState({ domain: e.target.value })}
              >
                {this.props.domains ? (
                  this.props.domains.map((option, key) => (
                    <MenuItem
                      className="options"
                      key={"-1" + key}
                      value={option}
                    >
                      {option.domain}
                    </MenuItem>
                  ))
                ) : (
                  <div />
                )}
              </Select>
            </div>
          </div>
          <div
            className="underMailboxFieldError"
            style={{
              display: this.props.add_status === 101 || this.props.add_status === 102 ? "block" : "none",
            }}
          >
            {i18next.t("mailLists.error_exist_mail")}
          </div>
          <div
            className="underMailboxFieldError"
            style={{
              display: this.state.emailFieldError ? "block" : "none",
            }}
          >
            {i18next.t("mailLists.invalid_mail_value")}
          </div>
          <div className="grid-container grid-container--fit">
            <div style={{ width: "832px", marginTop: "6px" }}>
              <Autocomplete
                className="newAliasArray"
                multiple
                filterSelectedOptions
                id="tags-standard"
                options={this.props.users.map((item) => item.mail)}
                noOptionsText={i18next.t("no_option")}
                clearText={i18next.t("clear")}
                getOptionLabel={(option) => option}
                filterOptions={(options) => {
                  return options.filter(
                    (item) => !this.state.maillistAlias.includes(item)
                  );
                }}
                onChange={(event, value) =>
                  this.setState({ maillistAlias: value })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{
                      maxHeight: "263px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                    }}
                    variant="standard"
                    id="maillistMembers"
                    label={i18next.t("mailLists.members")}
                    onChange={(e) => {
                      this.props.getUsers(1, e.target.value);
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="checkbox-line">
            <div style={{ width: "17px", height: "17px", marginTop: "3px" }}>
              <PsnCheckbox
                checked={this.state.localOnly}
                onChange={() =>
                  this.setState({ localOnly: !this.state.localOnly })
                }
              />
            </div>
            <div className="checkbox_text">
              {i18next.t("mailLists.forbid_ext_mail")}
            </div>
          </div>
        </form>
        <div
          style={{ position: "absolute", bottom: "32px", marginLeft: "20px" }}
        >
          <Button
            disabled={
              !this.state.maillistName ||
              this.state.maillistName.length > 255 ||
              !this.state.maillistEmail ||
              !this.state.maillistAlias ||
              this.state.emailFieldError ||
              this.state.maillistAlias.length === 0
            }
            onClick={this.handleaddNewMaillist}
            className={classes.mainButton}
          >
            {i18next.t("save")}
          </Button>
          <Button
            className={classes.cancelButton}
            onClick={this.handleCancel}
            style={{ marginLeft: "12px" }}
          >
            {i18next.t("cancel")}
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(NewMaillist)
);
