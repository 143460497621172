export const BEGIN_TENANTS_FETCH = "BEGIN_TENANTS_FETCH";
export const SUCCESS_TENANTS_FETCH = "SUCCESS_TENANTS_FETCH";
export const ADDING_NEW_DOMAIN = "ADDING_NEW_DOMAIN";
export const ADDED_NEW_DOMAIN = "ADDED_NEW_DOMAIN"; 
export const PREVENT_DOMAIN_ADD = "ADDED_NEW_DOMAIN";
// export const CLEAN_SELECTED_USER = "";
export const BEGIN_DOMAINS_FETCH = "BEGIN_DOMAINS_FETCH";
export const SUCCESS_DOMAINS_FETCH = "SUCCESS_DOMAINS_FETCH";
export const UPDATING_DOMAIN = "UPDATING_DOMAIN";
export const UPDATED_DOMAIN = "UPDATED_DOMAIN";
export const DELETED_DOMAIN = "DELETED_DOMAIN";
export const DELETING_DOMAIN = "DELETING_DOMAIN";