import React from "react";
import { HashRouter, Switch, Redirect } from "react-router-dom";
import "../styles/App.css";
import Login from "./Login/Login.js";
import PrivateRoute from "./Router/PrivateRoute.js";
import PublicRoute from "./Router/PublicRoute.js";
import Domains from "./Domains/Domains.js";
import NewDomain from "./Domains/NewDomain.js";
import DomainForm from "./Domains/DomainForm.js";
import Users from "./Users/Users.js";
import ResourceGroups from "./ResourceGroups/ResourceGroups.js";
import ResourceGroupForm from "./ResourceGroups/ResourceGroupForm.js";
import NewGroup from "./ResourceGroups/NewGroup.js";
import Sidebar from "./Sidebar/Sidebar.js";
import UserForm from "./Users/UserForm.js";
import Alert from "./Alerts/Alert.js";
import NewUser from "./Users/NewUser.js";
import Maillists from "./Maillists/Maillists.js";
import NewMaillist from "./Maillists/NewMaillist.js";
import NewResource from "./ResourceGroups/NewResource.js";
import EditResource from "./ResourceGroups/EditResource.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import MaillistForm from "./Maillists/MaillistForm.jsx";
import { SnackbarProvider} from 'notistack'
// import axios from "axios";

class App extends React.Component {
  constructor() {
    super();
    this.state = { baseUrlLoading: true };
  }

  componentDidMount() {
    fetch("/config.json")
    .then((r) => r.json())
      .then((data) => {
          window.PBM_API_URL = data.PBM_API_URL
          window.enable_users_edit = data.enable_users_edit
          window.enable_alternative_login = data.enable_alternative_login
          window.AVATARS_URL = data.AVATARS_URL
          window.PGS_ADMIN_URL = data.PGS_ADMIN_URL
          this.setState({baseUrlLoading: false})
      })
  }

  render() {
    let baseUrlLoading = this.state.baseUrlLoading;
    if (!baseUrlLoading) {
      return (       
        <HashRouter>
          <SnackbarProvider
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',           
            }}
          // style={{color: "red"}}
          />
          <Alert />
          <Switch>
            <PublicRoute login path="/login" component={Login} />
          </Switch>

          <Switch>
            <PrivateRoute exact path="/">
              <Redirect to="/users" />
            </PrivateRoute>

            {/* <PrivateRoute main path="/:tenant/domains">  */}
            <PrivateRoute main path= "/domains"> 
              <Sidebar />
              <Domains />
            </PrivateRoute>
             <PrivateRoute newDomain path="/domain/new">
            {/* <PrivateRoute newUser path="/:tenant/domains/new"> */}
              <Sidebar />
              <NewDomain />
            </PrivateRoute>
            {/* <PrivateRoute userForm path="/:tenant/domains/domain/edit"> */}
            <PrivateRoute domainForm path="/domain/:domain/edit">

              <Sidebar />
              <DomainForm />
            </PrivateRoute>





            <PrivateRoute main path="/users">
              <Sidebar />
              <Users />
            </PrivateRoute>
            <PrivateRoute newUser path="/user/new">
              <Sidebar />
              <NewUser />
            </PrivateRoute>
            <PrivateRoute userForm path="/user/:email/edit">
              <Sidebar />
              <UserForm />
            </PrivateRoute>

            <PrivateRoute groups path="/resource_groups">
             <Sidebar />
             <ResourceGroups />
             <PrivateRoute newGroup exact path="/resource_groups/group/new">
               <NewGroup />
             </PrivateRoute>
             <PrivateRoute editGroup exact path="/resource_groups/:groupName">
               <ResourceGroupForm />
             </PrivateRoute>
             <PrivateRoute
            editResource
            exact
            path="/resource_groups/:groupName/resource/:resourceId/edit"
            >
            <EditResource />
            </PrivateRoute> 
            <PrivateRoute
            addResource
            exact
            path="/resource_groups/:groupName/resource/new"
            >
            <NewResource />
            </PrivateRoute>
            </PrivateRoute>

            <PrivateRoute maillists path="/maillists">
              <Sidebar />
              <Maillists />
              <PrivateRoute newDomain exact path="/maillists/maillist/new">
                <NewMaillist />
              </PrivateRoute>
              <PrivateRoute editDomain exact path="/maillists/:maillistMail">
                <MaillistForm />
              </PrivateRoute>
            </PrivateRoute>
          </Switch>
        </HashRouter>
        
      );
    } else {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={3}>
            <CircularProgress />
          </Grid>
        </Grid>
      );
    }
  }
}

export default App;
