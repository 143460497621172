import {
  BEGIN_MAILLISTS_FETCH,
  SUCCESS_MAILLISTS_FETCH,
  CLEAN_SELECTED_MAILLIST,
  DELETED_MAILLIST,
  DELETING_MAILLIST,
  SELECTED_MAILLIST,
  ADDING_NEW_MAILLIST,
  ADDED_NEW_MAILLIST,
  UPDATED_MAILLIST,
  UPDATING_MAILLIST,
  STOP_LOADING
} from "../constants/MaillistsConstants";

const initialState = {
  maillistsFetching: false,
  maillists: [],
  selectedMaillist: "",
  deletingMaillist: false,
  selectedDisplayName: "",
  selectedEmail: "",
  selectedAlias: [],  
  addingNewMaillist: false,
  updatingMaillist: false,
  add_status: "",
  upd_status: ""
};

export const maillists = (state = initialState, action = {}) => {
  switch (action.type) {
    case BEGIN_MAILLISTS_FETCH:
      return Object.assign({}, state, {
        maillistsFetching: action.payload.maillistsFetching,
      });
    case SUCCESS_MAILLISTS_FETCH:
      return Object.assign({}, state, {
        maillistsFetching: action.payload.maillistsFetching,
        maillists: action.payload.maillists,
      });
    case CLEAN_SELECTED_MAILLIST:
      return Object.assign({}, state, {
        selectedMaillist: action.payload.selectedMaillist,
      });
    case ADDING_NEW_MAILLIST:
      return Object.assign({}, state, {
        addingNewMaillist: action.payload.addingNewMaillist,
      });
    case ADDED_NEW_MAILLIST:
      return Object.assign({}, state, {
        addingNewMaillist: action.payload.addingNewMaillist,
        add_status: action.payload.add_status,
      });
    case DELETING_MAILLIST:
      return Object.assign({}, state, {
        deletingMaillist: action.payload.deletingMaillist,
      });
    case DELETED_MAILLIST:
      return Object.assign({}, state, {
        deletingMaillist: action.payload.deletingMaillist,
      });
    case UPDATING_MAILLIST:
      return Object.assign({}, state, {
        updatingMaillist: action.payload.updatingMaillist,
      });
    case UPDATED_MAILLIST:
      return Object.assign({}, state, {
        updatingMaillist: action.payload.updatingMaillist,
        upd_status: action.payload.upd_status,
      });
    case SELECTED_MAILLIST:       
      return Object.assign({}, state, {
        selectedMaillist: action.payload,
      });
    case STOP_LOADING:       
      return Object.assign({}, state, {
        maillistsFetching: false,        
        deletingMaillist: false,
        updatingMaillist: false,
        addingNewMaillist: false,
      });       
    default:
      return state;
  }
};
