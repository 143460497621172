import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { createHashHistory } from "history";
import PropTypes from "prop-types";
import i18next from "i18next";
import {
  Button,
  TextField,
  CircularProgress,
  withStyles,
  MenuItem,
  Chip,
  IconButton
} from "@material-ui/core";
import PsnCheckbox from "./../GeneralElements/PsnCheckbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  getMaillists,
  getSelectedMaillist,
  updateMaillist,
  deleteMaillist,
  removeUpdStatus
} from "../../actions/MaillistsActions";
import { getUsers} from "../../actions/UsersActions";
import { getDomains } from "../../actions/DomainsActions";
import "../../styles/Maillists.css";
import { parser } from "../../allFunctions/parser.js";
import { mailParser } from "../../allFunctions/parser.js";
import Pencil from "../../assets/pencil.svg";
import DarkTooltip from "../../components/GeneralElements/DarkTooltip"
import { BorderColor } from "@material-ui/icons";

const mapStateToProps = (state) => ({
  maillistsFetching: state.maillists.maillistsFetching,
  maillists: state.maillists.maillists,
  usersFetching: state.users.usersFetching,
  users: state.users.users,  
  updatingMaillist: state.maillists.updatingMaillist,
  deletingMaillist: state.maillists.deletingMaillist,
  domains: state.domains.domains,
  domainsFetching: state.domains.domainsFetching,
  upd_status: state.maillists.upd_status,
  selectedMaillist: state.maillists.selectedMaillist,
});

const mapDispatchToProps = (dispatch) => ({
  getMaillists: () => dispatch(getMaillists()),
  getSelectedMaillist: (displayName) =>
    dispatch(getSelectedMaillist(displayName)),
  getUsers: (page, search) => dispatch(getUsers(page, search)),
  updateMaillist: (mail, displayName, newmail, alias, localOnly) =>
    dispatch(updateMaillist(mail, displayName, newmail, alias, localOnly)),
  deleteMaillist: (mail, alias) => dispatch(deleteMaillist(mail, alias)),
  getDomains: () => dispatch(getDomains()),
  removeUpdStatus: () => dispatch(removeUpdStatus())
});

const styles = (theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '4px'      
    },
    '*::-webkit-scrollbar-track': {
      // backgroundColor: "red",
      // width:"4px"
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: "rgba(25, 37, 46, 0.16)",
      // width:"14px",
      // paddingTop: "28px",
      borderRadius: "2px",      
    }
  },  
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    minHeight: "100vh",
    boxShadow:
      "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
  },
  infoForm: {
    height: "100%",
    marginBottom: "10px",
    padding: "10px",
    maxWidth: "850px",
    "& > *": {
      margin: "10px",
    },
  },
  iconButton: {
    width: "32px",
    height: "32px",
      '&:hover': {
        backgroundColor: "rgba(25, 37, 46, 0.08)",
        
      }
    
  },
  mainButton: {
    backgroundColor: "rgba(68, 187, 255, 0.56) !important",
    color: "rgba(25, 37, 46, 1)",
    width: "98px",
    height: "32px",
    marginRight: "4px",
    textTransform: "none",
    fontWeight: "525",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.35px",
    border: "1px solid rgba(25, 37, 46, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(68, 187, 255, 0.72) !important",
      color: "black",
      border: "1px solid rgba(25, 37, 46, 0.08)",
    },
    "&:disabled": {
      backgroundColor: "rgba(25, 37, 46, 0.08) !important",
      color: "rgba(163, 168, 171, 1)",
      border: "1px solid rgba(25, 37, 46, 0.08)",
    },
  },
  cancelButton: {
    color: "black",
    fontWeight: "525",
    width: "98px",
    height: "32px",
    textTransform: "none",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.35px",
    border: "1px solid #C0C0C0",
    "&:hover": {
      color: "black",
      backgroundColor: "rgba(25, 37, 46, 0.08) !important",
    },
  },
});

class MaillistForm extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.history = createHashHistory();
    this.state = {
      displayName: "",
      newmail: "",
      alias: [],
      maillistGroupName: "",
      maillistDomainEmail: "",
      maillistAlias: [],
      maillistAliasPrev: [],
      aliasToDel: [],
      mail: "",
      mailPerv: "",
      domain: "",     
      localOnly: "",
      localOnlyPerv: "",
      edit: false,     
      errorMail: false,
      emailFieldError: false,
      noChangeField: false,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.compareToUpd = this.compareToUpd.bind(this);
  }
  componentDidMount() {    
    this.props.getUsers(1);
    let maillistMail = this.props.match.params.maillistMail;
    this.setState({ mail: maillistMail });
    this.props.getDomains();
    this.props.maillists
      .filter((index) => index.mail === maillistMail)
      .map((item) => {
        return this.setState({
          maillistGroupName: item.displayName,
          maillistDomainEmail: item.mail.split("@")[0],
          domain: item.mail.split("@")[1],
          maillistAlias: item.alias,
          localOnly: item.accessTo,
          maillistGroupNamePrev: item.displayName,
          maillistDomainEmailPrev: item.mail.split("@")[0],
          domainPrev: item.mail.split("@")[1],
          maillistAliasPrev: item.alias,
          localOnlyPerv: item.accessTo,
        });
      });
  }

  componentDidUpdate(prevProps, prevState) {  
    if ((this.props !== prevProps) && !this.state.noChangeField) {      
      this.setState({ edit: false });
      this.props.maillists
        .filter((index) => index.mail === this.props.match.params.maillistMail)
        .map((item) => {
          return this.setState({
            mailPerv: this.props.match.params.maillistMail,
            mail: this.props.match.params.maillistMail,
            maillistGroupName: item.displayName,
            maillistDomainEmail: item.mail.split("@")[0],
            domain: item.mail.split("@")[1],
            maillistAlias: item.alias,
            maillistGroupNamePrev: item.displayName,
            maillistDomainEmailPrev: item.mail.split("@")[0],
            domainPrev: item.mail.split("@")[1],
            maillistAliasPrev: item.alias,
            localOnly: item.accessTo,
            localOnlyPerv: item.accessTo,
            errorMail: false,
          });
        });
      this.props.removeUpdStatus();
    }
    
  }

  handleCancel(e) {
    e.preventDefault();
    this.history.goBack();
  }

  nameChangeHandler(e) {
    e.preventDefault();
    if (
      e.target.value.match(/[^a-zA-Zа-яА-Я0-9_.-\s+]/g) ||
      e.target.value.length > 255
    ) {
      return;
    } else {
      this.setState({ maillistGroupName: parser(e.target.value) });
    }
  }

  handleListEmailChange(e) {
    e.preventDefault();
    let value = e.target.value;
    const tester = /^[0-9A-Za-z.]+$/;
    if (!value.match(tester) & (value !== "")) {
      this.setState({ emailFieldError: true });
    } else {
      this.setState({ emailFieldError: false });
    }
    this.setState({ maillistDomainEmail: mailParser(value) });
  }

  handleListEmailBlur(e) {
    e.preventDefault();
    let value = e.target.value;
    if (value && /\.$|-$|^\.|^-|(\W\W)/.test(value)) {
      return this.setState({ emailFieldError: true });
    }
    if (
      value.length < 1
      // || (value.split(".").find((item) => item.length < 2))
    ) {
      this.setState({ emailFieldError: true });
    } else this.setState({ emailFieldError: false });
  }

 async  compareToUpd() {
    this.setState({noChangeField: true}) 
    let newMail = this.state.maillistDomainEmail + "@" + this.state.domain
     await this.props.updateMaillist(
      this.state.mail,
      this.state.maillistGroupName.trim(),
      newMail !== this.state.mail ? newMail : "",
      JSON.stringify(this.state.maillistAlias),
      this.state.localOnly
     );
    this.setState({noChangeField: false}) 
  }  

  render() {
    let classes = this.props.classes;
    return this.props.maillistsFetching ? (
      <div className={classes.root}>
        <div className="loading">
          <CircularProgress />
        </div>
      </div>
    ) : (
      <div className={classes.root}>
        <form className={classes.infoForm} noValidate autoComplete="off">
          <div className="newListTitle" style={{ marginBottom: "32px" }}>
            {i18next.t("mailLists.edit_maiList")}
          </div>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <div style={{ marginLeft: "0px" }}>
              <TextField
                // className="maillistGroupName"
                style={{ width: "247px" }}
                id="maillistGroupName"
                label={i18next.t("mailLists.group_name")}
                type="text"
                value={this.state.maillistGroupName}
                onChange={(e) => {
                  // if (e.target.value === this.state.maillistGroupName) {
                  //   this.setState({ disabled: true })
                  // }
                  this.nameChangeHandler(e);
                }}
              />
            </div>
            <div style={{ marginLeft: "24px" }}>
              <TextField
                style={{ width: "247px" }}
                // className="maillistDomainEmail"
                id="maillistDomainEmail"
                label={i18next.t("mailLists.group_email")}
                type="text"
                value={this.state.maillistDomainEmail}
                onChange={(e, prevState) => {
                  this.handleListEmailChange(e);
                  // this.setState({ maillistDomainEmail: e.target.value })
                }}
                onBlur={(e) => this.handleListEmailBlur(e)}
              />
            </div>
            <div
              style={{
                marginLeft: "5px",
                marginRight: "7px",
                paddingBottom: "7px",
              }}
            >
              @
            </div>
            <div style={{ marginTop: "13px", width: "220px" }}>
              <TextField
                select
                // className="domainCellMaillist"
                style={{ width: "247px" }}
                value={this.state.domain ? this.state.domain : ""}
                disabled={this.props.domainsFetching}
                onChange={(e) => this.setState({ domain: e.target.value })}
              >
                {this.props.domains ? (
                  this.props.domains.map((option, key) => (
                    <MenuItem
                      className="options"
                      key={"-1" + key}
                      value={option.domain}
                    >
                      {option.domain}
                    </MenuItem>
                  ))
                ) : (
                  <div />
                )}
              </TextField>
            </div>
          </div>
          <div
            className="underMailboxFieldError"
            style={{
              display:
                this.props.upd_status === 101 || this.props.upd_status === 102
                  ? "block"
                  : "none",
            }}
          >
            {i18next.t("mailLists.error_exist_mail")}
          </div>
          <div
            className="underMailboxFieldError"
            style={{
              display: this.state.emailFieldError ? "block" : "none",
            }}
          >
            {i18next.t("mailLists.invalid_mail_value")}
          </div>
          <div style={{ flexDirection: "column" }}>
            <div
              className="membersTitle"
              style={{ display: "flex", alignItems: "center" }}
            >
              {i18next.t("mailLists.members")}
              <DarkTooltip
                title={<>{i18next.t("mailLists.edit")}</>}
                style={{ height: "100px" }}
              >
                <IconButton
                  className={classes.iconButton}
                  style={{ marginLeft: "24px" }}
                  onClick={() => this.setState({ edit: !this.state.edit })}
                >
                  <img className="trashImgHover" src={Pencil} alt={Pencil} />
                </IconButton>
              </DarkTooltip>
            </div>
            <div style={{ width: "832px", marginTop: "27px" }}>
              <Autocomplete
                className={this.state.edit ? "" : "autocompleteButton"}
                disabled={!this.state.edit}
                multiple
                id="tags-standard"
                value={this.state.maillistAlias}
                options={this.props.users.map((item) => item.mail)}
                filterOptions={(options) => {
                  return options.filter(
                    (item) => !this.state.maillistAlias.includes(item)
                  );
                }}
                getOptionLabel={(option) => option}
                noOptionsText={i18next.t("no_option")}
                clearText={i18next.t("clear")}
                onChange={(event, value) => {
                  this.setState({ maillistAlias: value });
                }}
                renderInput={(params) => (
                  <div className={this.state.edit ? "chipEdit" : "chip"}>
                    <TextField
                      {...params}
                      id="maillistMembers"
                      style={{
                        maxHeight: "263px",
                        overflowY: "scroll",
                        overflowX: "hidden",
                      }}
                      type="text"
                      variant="standard"
                      onChange={(e) => this.props.getUsers(1, e.target.value)}
                    />
                  </div>
                )}
              />
            </div>
          </div>

          <div className="checkbox-line">
            <div style={{ width: "17px", height: "17px", marginTop: "3px" }}>
              <PsnCheckbox
                checked={this.state.localOnly === "local_only"}
                onChange={() => {
                  if (this.state.localOnly === "local_only")
                    this.setState({ localOnly: "" });
                  else this.setState({ localOnly: "local_only" });
                }}
              />
            </div>
            <div className="checkbox_text">
              {i18next.t("mailLists.forbid_ext_mail")}
            </div>
          </div>
        </form>
        <div
          style={{ position: "absolute", bottom: "32px", marginLeft: "20px" }}
        >
          <Button
            disabled={
              (this.state.maillistGroupName ===
                this.state.maillistGroupNamePrev) &
                (this.state.maillistDomainEmail ===
                  this.state.maillistDomainEmailPrev) &
                (this.state.domain === this.state.domainPrev) &
                (this.state.maillistAlias.toString() ==
                  this.state.maillistAliasPrev.toString()) &
                (this.state.localOnly === this.state.localOnlyPerv) ||
              this.state.maillistAlias.length === 0 ||
              !this.state.maillistGroupName ||
              this.state.maillistGroupName.length > 255 ||
              !this.state.maillistDomainEmail ||
              !this.state.domain                
            }
            className={classes.mainButton}
            type="submit"
            onClick={() => {
              this.compareToUpd();
            }}
          >
            {i18next.t("save")}
          </Button>
          <Button
            className={classes.cancelButton}
            onClick={this.handleCancel}
            style={{ marginLeft: "12px" }}
          >
            {i18next.t("cancel")}
          </Button>
        </div>
      </div>
    );
  }
}

export default withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MaillistForm))
);
