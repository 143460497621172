import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {createStore, applyMiddleware, combineReducers} from 'redux'
import App from './components/App';
import thunk from 'redux-thunk'
import {Provider} from 'react-redux'
import {auth} from './reducers/LoginReducer.js'
import {alert} from './reducers/AlertsReducer.js'
import { users } from './reducers/UsersReducer.js'
import {domains} from './reducers/DomainsReduser'
import {resourceGroups} from './reducers/ResourceGroupsReducer.js'
import {maillists} from './reducers/MaillistsReducer.js'
import { emptyLoginPassword } from './middleware/LoginMiddleware'
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@material-ui/core";
import rm from "./RequestMiddleware/rm"; // request middleware

// import {filterResources} from './middleware/ResourceGroupsMiddleware'  
import * as serviceWorker from './serviceWorker';

const reducer = combineReducers({
    auth,
    domains,
    users,
    resourceGroups,
    maillists,
    alert
})

const store = createStore(reducer, applyMiddleware(thunk, emptyLoginPassword))
const theme = createTheme();

ReactDOM.render(
<ThemeProvider theme={theme}>
  <Provider store={store}>
    <App />
  </Provider>
</ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


// {
//     "PBM_API_URL": "https://pbm-black.myoffice-app.ru/"
// }